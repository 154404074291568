<script>
import { groupBy, sortBy, compact } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from '@/dayjs'
import { Line } from '@/vue-chartjs'
import { formatDate } from '@/misc/filters'
import currencyFormatter from '@/misc/currencyFormatter'
import { mapCons } from '@/misc/helpers'

export default {
  extends: Line,
  props: {
    startDate: Date,
    endDate: Date,
    // One of: day, month, year
    groupBy: String
  },
  data() {
    return {
      isUpdating: true,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value =>
                  currencyFormatter(value, this.activeCurrencyWithSubunit)
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              return formatDate(point.t, this.groupBy)
            },
            label: item =>
              `${currencyFormatter(
                item.yLabel,
                this.activeCurrencyWithSubunit
              )}/${this.groupBy}`
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    ...mapState('entries', ['entries'])
  },
  asyncComputed: {
    async chartdata() {
      this.isUpdating = true
      this.missingRateData = false
      const group = this.groupBy
      const currency = this.activeCurrencyWithSubunit
      const { startDate, endDate } = this
      // Convert entries to [{t, y}] object series
      const rawData = await this.getRawEntryData(currency)
      const compactedData = compact(rawData)
      if (rawData.length !== compactedData.length) {
        this.missingRateData = true
      }
      // Group appropriately
      const groupedData = await this.groupAndBoundData(
        compactedData,
        group,
        startDate,
        endDate
      )
      // Calculate the derivative
      const derivedData = mapCons(groupedData, (prev, next) => {
        const dTime = Math.ceil(dayjs(next.t).diff(dayjs(prev.t), group, true))
        const dWorth = next.y - prev.y
        return {
          t: prev.t,
          y: dWorth / dTime
        }
      })
      // Get +/- colours
      const colours = derivedData.map(({ y }) =>
        y < 0 ? '#ffdd57' : '#43ae43'
      )
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = group
      this.isUpdating = false
      return {
        datasets: [
          {
            label: `${this.getPluralGroupBy()} Rate of Change`,
            pointBorderColor: colours,
            pointBackgroundColor: colours,
            fill: false,
            data: derivedData
          }
        ]
      }
    }
  },
  watch: {
    chartdata() {
      if (this.chartdata) {
        this.renderChart(this.chartdata, this.options)
      }
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    getPluralGroupBy() {
      return { day: 'Daily', month: 'Monthly', year: 'Annual' }[this.groupBy]
    },
    async getRawEntryData(currency) {
      const promises = this.entries.map(async entry => {
        let rate
        try {
          rate = await this.getExchangeRateOn({
            currency,
            date: entry.date
          })
        } catch {
          return undefined
        }
        return {
          t: entry.date,
          y: entry.netWorth * rate
        }
      })
      return Promise.all(promises).then(data => sortBy(data, ({ t }) => t))
    },
    filterToDateRange(data, startDate, endDate) {
      const entries = []
      data.forEach(point => {
        if (startDate && dayjs(point.t).isBefore(startDate)) {
          return
        }
        if (endDate && dayjs(point.t).isAfter(endDate)) {
          return
        }
        entries.push(point)
      })
      return entries
    },
    boundData(data, period, startDate, endDate) {
      let startCursor = 0
      let endCursor = data.length
      // Bound the data - assumes data is sorted
      // Find the start of the data we're interested in
      if (startDate) {
        const windowStart = dayjs(startDate).startOf(period)
        for (let idx = 0; idx < data.length; ++idx) {
          if (windowStart.isSameOrBefore(data[idx].t)) {
            startCursor = idx
            break
          }
        }
      }
      // Find the end of the data we're interested in
      if (endDate) {
        const windowEnd = dayjs(endDate).endOf(period)
        for (let idx = data.length - 1; idx > startCursor; idx--) {
          if (windowEnd.isSameOrAfter(data[idx].t)) {
            endCursor = idx + 1
            break
          }
        }
        // We want to pick up more data for the derivative if possible
        if (endCursor < data.length) {
          const expandedEnd = dayjs(data[endCursor].t).endOf(period)
          for (let idx = endCursor; idx < data.length; ++idx) {
            if (expandedEnd.isSameOrAfter(data[idx].t)) {
              endCursor += 1
            }
          }
        }
      }
      return data.slice(startCursor, endCursor)
    },
    groupAndBoundData(data, period, startDate, endDate) {
      const boundedData = this.boundData(data, period, startDate, endDate)
      if (period === 'day') {
        return boundedData
      }
      let groups
      if (period === 'month') {
        groups = groupBy(boundedData, ({ t }) => dayjs(t).format('YYYY-MM'))
      } else {
        groups = groupBy(boundedData, ({ t }) => dayjs(t).format('YYYY'))
      }
      // We take the first value for each period as the basis for calculation
      const values = Object.values(groups).map(group => ({
        t: dayjs(group[0].t)
          .startOf(period)
          .toDate(),
        y: group[0].y
      }))
      return values
    }
  }
}
</script>
