<template lang="pug">
.breakdown-table
  b-select.mobile-sort-header(v-if="data" v-model="sortMode" :expanded="true")
    option(value="name") Sort by Type
    option(v-if="data.startMap" value="startTotal") Sort by Start Amount
    option(value="endTotal") Sort by End Amount
    option(v-if="data.startMap" value="change") Sort by Change
    option(v-if="data.startMap" value="velocity") Sort by Velocity
  .table-scroller
    table.table.is-fullwidth(v-if="data" :class='{"is-striped": !showHoldings, "with-holdings": showHoldings}')
      thead
        sortable-th(v-model="sortMode" type="name") Type
        sortable-th(v-if="data.startMap" v-model="sortMode" type="startTotal") {{ startDateFormatted }}
        sortable-th(v-model="sortMode" type="endTotal") {{ endDateFormatted }}
        sortable-th(v-if="data.startMap" v-model="sortMode" type="change") Change
        sortable-th(v-if="data.startMap" v-model="sortMode" type="velocity") Velocity
      tbody
        template(v-for="type in sortedTypes")
          tr.type-row
            td.holding-name
              span(v-if="!showHoldings || (showHoldings && type.holdings.length > 1)") {{ type.name }}
              span(v-else) {{ type.holdings[0].name }}
            td(v-if="data.startMap")
              span.td-label {{ startDateFormatted }}
              span.td-value(v-if="type.startTotal") {{ type.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
              span.td-value(v-if="!type.startTotal") -
            td
              span.td-label {{ endDateFormatted }}
              span.td-value(v-if="type.endTotal") {{ type.endTotal | currencyFormatter(activeCurrencyWithSubunit) }}
              span.td-value(v-if="!type.endTotal")
            td(v-if="data.startMap")
              span.td-label Change
              span.td-value
                | {{ type.endTotal - type.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
                span(v-if="type.startTotal") &nbsp;{{ type | getPercentChange }}
            td(v-if="data.startMap")
              span.td-label Velocity
              span.td-value {{ type.velocity | currencyFormatter(activeCurrencyWithSubunit)}}/{{groupBy}}
          template(v-if="showHoldings && type.holdings.length > 1")
            tr.detail-row(v-for="(holding, idx) in sortHoldings(type.holdings)" :class='{"is-first": !idx, "is-last": idx == type.holdings.length - 1}')
              td.holding-name {{ holding.name }}
              td(v-if="data.startMap")
                span.td-label {{ startDateFormatted }}
                span.td-value(v-if="holding.startTotal") {{ holding.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
                span.td-value(v-if="!holding.startTotal") -
              td
                span.td-label {{ endDateFormatted }}
                span.td-value(v-if="holding.endTotal") {{ holding.endTotal | currencyFormatter(activeCurrencyWithSubunit) }}
                span.td-value(v-if="!holding.endTotal") -
              td(v-if="data.startMap")
                span.td-label Change
                span.td-value
                  | {{ holding.endTotal - holding.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
                  span(v-if="holding.startTotal") &nbsp;{{ holding | getPercentChange }}
              td(v-if="data.startMap")
                span.td-label Velocity
                span.td-value {{ holding.velocity | currencyFormatter(activeCurrencyWithSubunit)}}/{{groupBy}}
        tr.summary-row
          td.holding-name Total
          td(v-if="data.startMap")
            span.td-label {{ startDateFormatted }}
            span.td-value {{ data.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
          td
            span.td-label {{ endDateFormatted }}
            span.td-value {{ data.endTotal | currencyFormatter(activeCurrencyWithSubunit) }}
          td(v-if="data.startMap")
            span.td-label Change
            span.td-value
              | {{ data.endTotal - data.startTotal | currencyFormatter(activeCurrencyWithSubunit) }}
              | &nbsp;
              | {{ data | getPercentChange }}
          td(v-if="data.startMap")
            span.td-label Velocity
            span.td-value {{ data.velocity | currencyFormatter(activeCurrencyWithSubunit)}}/{{groupBy}}
</template>
<script>
import { sortBy } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'
import { formatDate } from '@/misc/filters'
import currencyFormatter from '@/misc/currencyFormatter'
import { getTypeName } from '@/misc/type_map'
import SortableTh from '@/components/charts/SortableTh'

export default {
  components: {
    SortableTh
  },
  filters: {
    formatDate,
    currencyFormatter,
    getPercentChange({ startTotal, endTotal }) {
      if (startTotal === 0) {
        return ''
      }
      const fraction = (endTotal - startTotal) / startTotal
      return `(${Math.round(fraction * 1000) / 10}%)`
    }
  },
  props: {
    startDate: Date,
    endDate: Date,
    disable: Object,
    groupBy: String,
    showHoldings: Boolean
  },
  data() {
    return {
      isUpdating: true,
      version: 0,
      sortMode: localStorage.getItem('HoldingTable/sortMode') || 'endTotal'
    }
  },
  computed: {
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    ...mapGetters('categories', ['categoryWeight']),
    ...mapState('entries', ['entries']),
    startEntry() {
      return (
        this.$store.getters['entries/lastEntry'](this.startDate) ||
        this.$store.getters['entries/nextEntry'](this.startDate)
      )
    },
    endEntry() {
      return this.$store.getters['entries/lastEntry'](this.endDate)
    },
    startDateFormatted() {
      if (!this.data.startMap) {
        return ''
      }
      const { startEntry, startDate } = this
      return formatDate((startEntry && startEntry.date) || startDate)
    },
    endDateFormatted() {
      const { endEntry, endDate } = this
      return formatDate((endEntry && endEntry.date) || endDate)
    },
    sortedTypes() {
      const { data, sortMode, showHoldings } = this
      if (!data) {
        return null
      }
      switch (sortMode) {
        case 'name':
          return sortBy(data.types, ({ holdings, name }) => {
            if (!showHoldings || (showHoldings && holdings.length > 1)) {
              return name
            }
            return holdings[0].name
          })
        case 'startTotal':
          return sortBy(data.types, ({ startTotal }) => startTotal).reverse()
        case 'endTotal':
          return sortBy(data.types, ({ endTotal }) => endTotal).reverse()
        case 'change':
          return sortBy(
            data.types,
            ({ startTotal, endTotal }) => endTotal - startTotal
          ).reverse()
        case 'velocity':
          return sortBy(data.types, ({ velocity }) => velocity).reverse()
        default:
          return data.types
      }
    }
  },
  asyncComputed: {
    async data() {
      this.isUpdating = true
      this.missingRateData = false
      const { endEntry, groupBy } = this
      let { startEntry } = this
      const currency = this.activeCurrencyWithSubunit
      const disabled = this.disable || {}
      const version = this.version // eslint-disable-line
      if (!endEntry) return undefined
      // If start & end entries are the same just show a single column
      // rather than repeating the data twice
      if (startEntry && startEntry.id === endEntry.id) startEntry = null

      const typeMap = {}
      const categories = {}

      const getMap = (entry, rate) => {
        const dataMap = new Map()
        const getData = ({ categoryId, amount, baseRate }) => {
          // Skip disabled series
          if (disabled[categoryId]) return
          const category = this.$store.getters['categories/getCategoryById'](
            categoryId
          )
          categories[categoryId] = category
          const baseAmount = amount / baseRate
          let categoryType = category.type
          if (categoryType === 'custom' && category.customName) {
            categoryType = category.customName
          }
          typeMap[categoryType] = true
          if (!dataMap.has(categoryType)) {
            dataMap.set(categoryType, [])
          }
          dataMap.get(categoryType).push({
            id: category.id,
            name: category.name,
            amount: baseAmount * rate,
            isAsset: category.isAsset
          })
        }
        entry.assets.forEach(getData)
        entry.liabilities.forEach(getData)
        return dataMap
      }
      let startMap
      let endMap
      let dateDiff = 1
      if (startEntry) {
        try {
          const startRate = await this.getExchangeRateOn({
            date: startEntry.date,
            currency
          })
          startMap = getMap(startEntry, startRate)
          dateDiff = dayjs(endEntry.date).diff(startEntry.date, groupBy, true)
        } catch {
          this.missingRateData = true
          startEntry = null
        }
      }
      try {
        const endRate = await this.getExchangeRateOn({
          date: endEntry.date,
          currency
        })
        endMap = getMap(endEntry, endRate)
      } catch {
        this.missingRateData = true
        return undefined
      }

      let startSum = 0
      let endSum = 0
      const types = Object.keys(typeMap).map(typeId => {
        let startTotal = 0
        let endTotal = 0

        const holdings = {}

        const getHolding = id => {
          if (id in holdings) {
            return holdings[id]
          }
          const category = categories[id]
          holdings[id] = {
            id,
            name: category.name,
            startTotal: 0,
            endTotal: 0
          }
          return holdings[id]
        }

        if (startMap) {
          const startHoldings = startMap.get(typeId)
          if (startHoldings) {
            startHoldings.forEach(holding => {
              if (holding.isAsset) startTotal += holding.amount
              else startTotal -= holding.amount

              const holdingInfo = getHolding(holding.id)
              holdingInfo.startTotal = holding.isAsset
                ? holding.amount
                : -holding.amount
            })
          }
        }
        const endHoldings = endMap.get(typeId)
        if (endHoldings) {
          endHoldings.forEach(holding => {
            if (holding.isAsset) endTotal += holding.amount
            else endTotal -= holding.amount

            const holdingInfo = getHolding(holding.id)
            holdingInfo.endTotal = holding.isAsset
              ? holding.amount
              : -holding.amount
            if (startMap) {
              holdingInfo.velocity =
                (holdingInfo.endTotal - holdingInfo.startTotal) / dateDiff
            }
          })
        }

        const type = {
          name: getTypeName(typeId),
          startTotal,
          endTotal,
          holdings: Object.values(holdings)
        }
        if (startMap) {
          type.velocity = (endTotal - startTotal) / dateDiff
        }
        startSum += startTotal
        endSum += endTotal
        return type
      })

      this.isUpdating = false

      let velocity
      if (startMap) {
        velocity = (endSum - startSum) / dateDiff
      }

      return {
        types,
        startMap,
        endMap,
        startTotal: startSum,
        endTotal: endSum,
        velocity
      }
    }
  },
  watch: {
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    },
    data: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        const { startMap } = val
        if (!startMap && this.sortMode !== 'name') {
          this.sortMode = 'endTotal'
        }
      }
    },
    sortMode(val) {
      localStorage.setItem('HoldingTable/sortMode', val)
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    recompute() {
      this.version += 1
    },
    sortHoldings(holdings) {
      const { categoryWeight, sortMode } = this
      switch (sortMode) {
        case 'name':
          return sortBy(holdings, ({ name }) => name)
        case 'startTotal':
          return sortBy(holdings, ({ startTotal }) => startTotal).reverse()
        case 'endTotal':
          return sortBy(holdings, ({ endTotal }) => endTotal).reverse()
        case 'change':
          return sortBy(
            holdings,
            ({ startTotal, endTotal }) => endTotal - startTotal
          ).reverse()
        case 'velocity':
          return sortBy(holdings, ({ velocity }) => velocity).reverse()
        default:
          return sortBy(holdings, ({ id }) => categoryWeight(id))
      }
    }
  }
}
</script>
<style scoped lang="sass">
.table-scroller
  overflow-x: auto
.summary-row
  font-weight: bold
  border-top: 3px double #444
  background: white !important
.detail-row
  background: #fafafa
  .holding-name
    padding-left: 2rem
.type-row + .detail-row td
  border: none
.detail-row + .detail-row td
  border: none
.detail-row.is-last
  border: 1px solid #dbdbdb
  border-width: 0 0 1px
.table
  th, td
    text-align: left
  th
    white-space: nowrap
.td-label
  display: none
.mobile-sort-header
  display: none
// Responsive table rules
@media screen and (max-width: 768px)
  .mobile-sort-header
    display: block
    margin-bottom: 0.5rem
  .table-scroller
    margin-left: -1.25rem
    margin-right: -1.25rem
  table, thead, tbody, tr, td
    display: block
  table, tbody
    width: 100%
  thead
    display: none
  td
    position: relative
    border: none !important
    padding: 1.25rem 0.5rem 0.5rem
    &.holding-name
      padding-top: 0
      font-weight: bold
  .td-label
    display: block
    font-size: 0.8rem
    font-weight: normal
    color: #555
    position: absolute
    top: 0
    left: 0.5rem
  .td-value
    overflow-wrap: break-word
  .holding-name
    position: absolute
    top: 0.5rem
    left: 0.75rem
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    width: 95%
  td
    flex: 1
    flex-basis: 50%
    width: 50%
    min-width: 160px
  tr
    display: flex
    flex-wrap: wrap
    position: relative
    padding: 2rem 0.75rem 0
  .summary-row
    padding-top: 2.5rem
    margin-top: 0.5rem
    border-top: 3px double #dbdbdb
    .holding-name
      top: 1rem
    .td-value
      font-weight: normal
  .detail-row
    &.is-first
      box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
    &.is-last
      box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
      border-width: 0
    .holding-name
      padding-left: 0.5rem
      font-size: 0.9rem
  tr
    border-top: 1px solid #dbdbdb
    &:first-child
      border: none
</style>
