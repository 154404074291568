import { render, staticRenderFns } from "./HoldingTable.vue?vue&type=template&id=3309961b&scoped=true&lang=pug&"
import script from "./HoldingTable.vue?vue&type=script&lang=js&"
export * from "./HoldingTable.vue?vue&type=script&lang=js&"
import style0 from "./HoldingTable.vue?vue&type=style&index=0&id=3309961b&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3309961b",
  null
  
)

export default component.exports