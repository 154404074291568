<template lang="pug">
section.section.analyze-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .inner(v-if="!initializing")
    .columns
      .column.is-12.header-flex
        h1.title          
        display-currency-select
    .columns
      .column
        b-field(label="Start Date")
          b-datepicker(:value="startDate" @input="setStartDate" :date-parser="dateParser" :date-formatter="dateFormatterLong" :max-date="today")
      .column
        b-field(label="End Date")
          b-datepicker(:value="endDate" @input="setEndDate" :date-parser="dateParser" :date-formatter="dateFormatterLong" :max-date="today")
      .column
        b-field(label="Group by")
          b-select.group-by-select(:value="groupBy" @input="setGroupBy")
            option(value="day") Day
            option(value="month") Month
            option(value="year") Year
    .columns(v-if="entryCount < 2")
      .column.is-12
        b-message(type="is-info" title="No data" v-if="entryCount == 0")
          | You don't have any matching data to analyze!
        b-message(type="is-info" title="Limited data" v-if="entryCount == 1")
          | You only have one matching data point. Add another for way more analyses!
    .columns(v-if="entryCount > 1")
      .column.is-12
        .box
          .net-worth
            h2.subtitle Net Worth
            loading-wrapper
              compound-area(mode="networth" :start-date="startDate" :end-date="endDate" :group-by="groupBy" chart-id="networth-area")
    .columns(v-if="entryCount > 1")
      .column.is-6
        .box
          h2.subtitle Net Assets
          loading-wrapper
            compound-area(mode="assets" :start-date="startDate" :end-date="endDate" :group-by="groupBy" chart-id="netassets-area")
      .column.is-6
        .box
          h2.subtitle Net Liabilities
          loading-wrapper
            compound-area(mode="liabilities" :start-date="startDate" :end-date="endDate" :group-by="groupBy" chart-id="netliabilities-area")
    .columns(v-if="entryCount > 2")
      .column.is-12
        .box
          .net-worth
            h2.subtitle {{ pluralGroupBy }} Rate of Change
            loading-wrapper
              differential-area(:start-date="startDate" :end-date="endDate" :group-by="groupBy" ref='changeChart' chart-id="rate-of-change")
    .columns(v-if="entryCount")
      .column.is-6
        .box
          h2.subtitle Current Asset Breakdown
          loading-wrapper
            breakdown-pie(:date="endDate" mode="assets" chart-id="asset-breakdown")
      .column.is-6
        .box
          h2.subtitle Current Liability Breakdown
          loading-wrapper
            breakdown-pie(:date="endDate" mode="liabilities" chart-id="liability-breakdown")
    .columns(v-if="entryCount")
      .column.is-12
        .box
          .net-worth
            h2.subtitle Historic Asset Mix
            loading-wrapper
              breakdown-chart(:start-date="startDate" :end-date="endDate" :group-by="groupBy" chart-id="breakdown-chart")
    .columns(v-if="entryCount")
      .column.is-12
        .box
          .header-with-control
            h2.subtitle Holdings
            b-switch.holding-toggle(v-model="showHoldings") Detailed
          loading-wrapper
            holding-table(:start-date="startDate" :end-date="endDate" :group-by="groupBy" :show-holdings="showHoldings")
</template>
<script>
import { groupBy } from 'lodash'
import { mapState } from 'vuex'
import dayjs from '@/dayjs'
import { formatDate } from '@/misc/filters'
import LoadingWrapper from '@/components/LoadingWrapper.vue'
import DisplayCurrencySelect from '@/components/DisplayCurrencySelect.vue'
import CompoundArea from '@/components/charts/CompoundArea.vue'
import BreakdownChart from '@/components/charts/BreakdownChart.vue'
import BreakdownPie from '@/components/charts/BreakdownPie.vue'
import DifferentialArea from '@/components/charts/DifferentialArea.vue'
import HoldingTable from '@/components/charts/HoldingTable.vue'
import { dateParser, dateFormatterLong } from '@/misc/helpers'

export default {
  name: 'AnalyzeView',
  components: {
    BreakdownChart,
    BreakdownPie,
    DisplayCurrencySelect,
    CompoundArea,
    DifferentialArea,
    LoadingWrapper,
    HoldingTable
  },
  filters: {
    formatDate
  },
  data() {
    const today = dayjs()
      .startOf('day')
      .toDate()
    const savedStart = localStorage.getItem('analyzeStartPreference')
    let startDate
    if (this.$route.query.start) {
      startDate = dayjs(this.$route.query.start).toDate()
    } else if (savedStart) {
      startDate = dayjs(savedStart).toDate()
    }
    if (!startDate || today < startDate) {
      startDate = dayjs(today)
        .startOf('year')
        .subtract(1, 'year')
        .toDate()
    }
    return {
      today,
      groupBy: this.$route.query.group || 'month',
      startDate,
      endDate: dayjs(this.$route.query.end || today).toDate(),
      showHoldings: localStorage.getItem('showHoldingsPreference') !== 'false'
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('entries', ['entries', 'loadingEntries']),
    ...mapState('categories', ['loadingCategories']),
    initializing() {
      return (
        this.loadingCurrencies || this.loadingEntries || this.loadingCategories
      )
    },
    pluralGroupBy() {
      return { day: 'Daily', month: 'Monthly', year: 'Annual' }[this.groupBy]
    },
    entryCount() {
      let count = 0
      if (this.entries) {
        const matchingEntries = []
        this.entries.forEach(entry => {
          if (this.startDate && dayjs(entry.date).isBefore(this.startDate)) {
            return
          }
          if (this.endDate && dayjs(entry.date).isAfter(this.endDate)) {
            return
          }
          matchingEntries.push(entry)
        })
        const groupedEntries = groupBy(matchingEntries, entry => {
          let fmt = ''
          if (this.groupBy === 'day') {
            fmt = 'YYYY-MM-DD'
          }
          if (this.groupBy === 'month') {
            fmt = 'YYYY-MM'
          }
          if (this.groupBy === 'year') {
            fmt = 'YYYY'
          }
          return dayjs(entry.date).format(fmt)
        })
        count = Object.keys(groupedEntries).length
      }
      return count
    }
  },
  watch: {
    '$route.query.start': function(value) {
      if (value) this.startDate = dayjs(value).toDate()
    },
    '$route.query.end': function(value) {
      if (value) this.endDate = dayjs(value).toDate()
    },
    '$route.query.group': function(value) {
      this.groupBy = value || 'month'
    },
    startDate(value) {
      localStorage.setItem(
        'analyzeStartPreference',
        dayjs(value).format('YYYY-MM-DD')
      )
    },
    showHoldings(value) {
      localStorage.setItem('showHoldingsPreference', value ? 'true' : 'false')
    }
  },
  methods: {
    dateParser,
    dateFormatterLong,
    setStartDate(date) {
      this.$router.push({
        query: {
          start: dayjs(date).format('YYYY-MM-DD'),
          end: dayjs(this.endDate).format('YYYY-MM-DD'),
          group: this.groupBy
        }
      })
    },
    setEndDate(date) {
      this.$router.push({
        query: {
          start: dayjs(this.startDate).format('YYYY-MM-DD'),
          end: dayjs(date).format('YYYY-MM-DD'),
          group: this.groupBy
        }
      })
    },
    setGroupBy(value) {
      this.$router.push({
        query: {
          start: dayjs(this.startDate).format('YYYY-MM-DD'),
          end: dayjs(this.endDate).format('YYYY-MM-DD'),
          group: value
        }
      })
    }
  },
  metaInfo: {
    title: 'Analyze your Holdings'
  }
}
</script>
<style lang="sass" scoped>
.header-flex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  .title
    flex-grow: 1
    white-space: nowrap
::v-deep .group-by-select
  .select, select
    width: 100%
.title
  .active
    color: #333
    border-bottom: 3px solid #333
  .inactive
    color: hsl(149, 51%, 41%)
  a, span
    margin-right: 1rem
.holding-toggle
  margin-bottom: 1.5rem
.header-with-control
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  h2
    margin-right: 1.5rem
@media screen and (max-width: 600px)
  .header-flex
    .title
      width: auto
      order: 0
    .display-currency-select
      max-width: 100%
      margin-right: 0
      order: 2
</style>
