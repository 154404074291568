<template lang="pug">
th(@click="$emit('input', type)" :class="classes")
  .clickable
    slot
  b-icon.is-small(icon="chevron-down" v-show="selected")
</template>

<script>
export default {
  props: {
    type: String,
    value: String
  },
  computed: {
    selected() {
      const { type, value } = this
      return type === value
    },
    classes() {
      const { selected } = this
      return {
        selected,
        deselected: !selected
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.clickable
  display: inline-block
.deselected .clickable
  cursor: pointer
  margin-right: 2.5rem
  &:hover
    transform: scale(1.1)
.icon
  margin-left: 0.75rem
  position: relative
  top: 0.1rem
</style>
